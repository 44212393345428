import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import styles from "./section.module.css";
import { useModifiers } from "../../../hooks/useModifiers";
import { Container } from "../container";

export const SectionTitle: FunctionComponent<
  HTMLAttributes<HTMLHeadingElement>
> = ({ children, className, ...rest }) => {
  return (
    <h2
      className={`${styles.section__title} ${className} h1 h1_noMargin`}
      {...rest}
    >
      {children}
    </h2>
  );
};

export interface SectionProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title?: string | ReactNode;
  description?: string | ReactNode;
  inverted?: boolean;
  shade?: boolean;
}

export const Section: FunctionComponent<SectionProps> = ({
  children,
  title,
  description,
  inverted,
  shade,
  className,
}) => {
  const mods = useModifiers(
    styles,
    "section",
    { inverted,shade },
    { withBaseSelector: true, inherited: className }
  );

  return (
    <section className={mods}>
      {title || description ? (
        <Container className={styles.section__titleContainer}>
          {title ? (
            typeof title === "string" ? (
              <SectionTitle dangerouslySetInnerHTML={{ __html: title }} />
            ) : (
              title
            )
          ) : null}
          {description ? (
            <div className={`${styles.section__description} text`}>
              <p>{description}</p>
            </div>
          ) : null}
        </Container>
      ) : null}

      {children}
    </section>
  );
};
