import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import { Container } from "../container";
import styles from "./pageHeader.module.css";
import { useModifiers } from "../../../hooks/useModifiers";

export interface PageHeaderProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  image?: string;
}

export const PageHeader: FunctionComponent<PageHeaderProps> = ({
  title,
  subtitle,
  children,
  image,
}) => {
  const mods = useModifiers(styles, "pageHeader", { image: !!image });

  return (
    <div className={`${styles.pageHeader} ${mods}`}>
      {image ? (
          <div className={styles.pageHeader__imageContainer}>
            <img className={styles.pageHeader__image} src={image} alt="" />
          </div>
      ) : null}
      <Container>
        <div className={styles.pageHeader__content}>
          <div className={styles.pageHeader__subtitle}>{subtitle}</div>
          {typeof title === "string" ? (
            <h1 className={`h1 h1_noMargin ${styles.pageHeader__title}`}>
              {title}
            </h1>
          ) : (
            title
          )}
          {children ? (
            <div className={`${styles.pageHeader__text} text`}>{children}</div>
          ) : null}
        </div>
      </Container>
    </div>
  );
};
