import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import styles from "./featureCard.module.css";

export interface FeatureCardProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title?: string | ReactNode;
  icon?: string;
}

export const FeatureCard: FunctionComponent<FeatureCardProps> = ({
  title,
  children,
  icon,
}) => {
  return (
    <div className={styles.featureCard}>
      {icon ? (
        <img className={styles.featureCard__icon} src={icon} alt={""} />
      ) : null}
      {title ? (
        <div className={`${styles.featureCard__title}`}>
          <h2 className={`h3 h3_noMargin`}>{title}</h2>
        </div>
      ) : null}
      <div className={`${styles.featureCard__content} text`}>{children}</div>
    </div>
  );
};
