import React, { FunctionComponent, useMemo, useState } from 'react';
import styles from './cookiePopup.module.css';
import { useTranslator } from '../../../translations/useTranslation';
import { MarkdownParser } from '../textBlock';
import { AnchorButton, Button, LinkButton } from '../../common/button';
import { Translation } from '../../../translations/translation';
import { getCookie, setCookie } from '../../../helpers/cookies';
import { useConfigData } from 'hooks/useConfigData';

export const CookiePopup: FunctionComponent = () => {
  const cookieValue = useMemo(() => getCookie('cookieAccept'), []);
  const { data: config } = useConfigData();
  const translator = useTranslator();
  const [open, setOpen] = useState(!cookieValue);

  if (!config || !config.cookieText || !open) {
    return null;
  }

  return (
    <div className={styles.cookiePopup}>
      <div className={styles.cookiePopup__content}>
        <MarkdownParser text={translator(config.cookieText)} />
      </div>
      <div className={styles.cookiePopup__footer}>
        <div className={styles.cookiePopup__footerWing}>
          <Button
            outline
            onClick={() => {
              setOpen(false);
              setCookie('cookieAccept', 'true', 365);
            }}
          >
            <Translation k={'Got it'} />
          </Button>
          {config.cookieLearnLink ? (
            config.cookieLearnLink.link.includes('://') ? (
              <AnchorButton
                ghost
                href={config.cookieLearnLink.link}
                target={config.cookieLearnLink.target}
              >
                {config.cookieLearnLink.title}
              </AnchorButton>
            ) : (
              <LinkButton ghost to={config.cookieLearnLink.link}>
                {config.cookieLearnLink.title}
              </LinkButton>
            )
          ) : null}
        </div>
        <div className={styles.cookiePopup__footerWing}>
          <Button ghost onClick={() => setOpen(false)}>
            <svg width={24} height={24} xmlns="http://www.w3.org/2000/svg">
              <use href="#mobileMenuClose" />
            </svg>
          </Button>
        </div>
      </div>
    </div>
  );
};
