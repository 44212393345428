import {
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./titleDropdown.module.css";
import { useModifiers } from "../../../hooks/useModifiers";

export interface TitleDropdownProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  options: { value: string; label: string }[];
  onChange: (value: string) => void;
  value: string;
}

export const TitleDropdown: FunctionComponent<TitleDropdownProps> = ({
  options,
  onChange,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const mods = useModifiers(styles, "titleDropdown", { open });

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };
    const clickOutsideHandler = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener("keydown", handler);
    document.addEventListener("click", clickOutsideHandler);
    return () => {
      document.removeEventListener("keydown", handler);
      document.removeEventListener("click", clickOutsideHandler);
    };
  }, [open]);

  const handleSelect = (value: string) => {
    setOpen(false);
    onChange(value);
  };

  return (
    <div className={`${styles.titleDropdown} ${mods}`} ref={ref}>
      <button
        className={styles.titleDropdown__trigger}
        onClick={() => setOpen(!open)}
      >
        <span className={styles.titleDropdown__triggerLabel}>
          {options.find((item) => value === item.value)?.label}
        </span>
      </button>
      <div className={styles.titleDropdown__dropdown}>
        {options.map((item) => (
          <button
            className={styles.titleDropdown__dropdownButton}
            key={item.value}
            onClick={() => handleSelect(item.value)}
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};
