import React, {
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useModifiers } from '../../../../hooks/useModifiers';
import styles from './paymentDropdown.module.css';

export interface PaymentDropdownProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'> {
  options: { value: number; label: string }[];
  onSelect?: (value: number) => void;
  value: number;
}

export const PaymentDropdown: FunctionComponent<PaymentDropdownProps> = ({
  options,
  onSelect,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const mods = useModifiers(styles, 'paymentDropdown', { open });

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false);
      }
    };
    const clickOutsideHandler = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener('keydown', handler);
    document.addEventListener('click', clickOutsideHandler);
    return () => {
      document.removeEventListener('keydown', handler);
      document.removeEventListener('click', clickOutsideHandler);
    };
  }, [open]);

  const handleSelect = (value: number) => {
    if (onSelect) {
      onSelect(value);
    }
    setOpen(false);
  };

  return (
    <div className={`${styles.paymentDropdown} ${mods}`} ref={ref}>
      <button
        className={styles.paymentDropdown__trigger}
        onClick={() => setOpen(!open)}
      >
        <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg">
          <use href={`#${options.find((el) => el.value === value)?.label}`} />
        </svg>
        <span className={styles.paymentDropdown__triggerLabelWrapper}>
          {options.map((item) => (
            <span
              key={item.value}
              className={`${styles.paymentDropdown__triggerLabel} ${
                item.value !== value
                  ? styles.paymentDropdown__triggerLabel_hidden
                  : ''
              }`}
            >
              {item.label}
            </span>
          ))}
        </span>
        {options.length > 1 ? (
          <svg width={11} height={7} xmlns="http://www.w3.org/2000/svg">
            <use href="#chevronDownTiny" />
          </svg>
        ) : null}
      </button>
      {options.length > 1 ? (
        <div className={styles.paymentDropdown__dropdown}>
          {options
            .filter((item) => item.value !== value)
            .map((item) => (
              <button
                className={styles.paymentDropdown__dropdownButton}
                key={item.value}
                onClick={() => handleSelect(item.value)}
              >
                <svg width={16} height={16} xmlns="http://www.w3.org/2000/svg">
                  <use href={`#${item.label}`} />
                </svg>
                {item.label}
              </button>
            ))}
        </div>
      ) : null}
    </div>
  );
};
