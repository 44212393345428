import { FunctionComponent, HTMLAttributes } from 'react';
import { useFormattedPrice } from '../../../hooks/useFormattedPrice';
import styles from './priceTableRow.module.css';
import { DayCourseItem } from '../../../store/api/prices/types';
import { Translation } from '../../../translations/translation';

export interface PriceTableRowProps
  extends HTMLAttributes<HTMLTableRowElement> {
  row: DayCourseItem;
  fiat: string;
}

export const CourseRow: FunctionComponent<PriceTableRowProps> = ({
  row,
  fiat,
}) => {
  const price = useFormattedPrice({ value: +row.value, currency: fiat });
  // const change = useFormattedPrice(priceRow.price24Change);
  const isPositive = row.change.includes('+');

  return (
    <tr className={styles.priceTableRow}>
      <td className={styles.center}>
        <span className={styles.circle}>
          <span className={`icon-${row.from}`}></span>
        </span>
      </td>
      <td>{row.from}</td>
      <td className={styles.priceTableRow__data}>
        <span className={styles.priceTableRow__label}>
          <Translation k={'Price'} />
        </span>
        <span>{price}</span>
      </td>
      {/* <td
        className={`${
          isPositive
            ? styles.priceTableRow__cell_positive
            : styles.priceTableRow__cell_negative
        } ${styles.priceTableRow__data}`}
      >
        <span className={styles.priceTableRow__label}>
          <Translation k={'24hr Change'} />
        </span>
        <span>{change}</span>
      </td> */}
      <td
        className={`${
          isPositive
            ? styles.priceTableRow__cell_positive
            : styles.priceTableRow__cell_negative
        } ${styles.priceTableRow__data}`}
      >
        <span className={styles.priceTableRow__label}>
          <Translation k={'% Change'} />
        </span>
        <span>{row.change}</span>
      </td>

      <td
        className={`${styles.priceTableRow__data} ${styles.priceTableRow__data_hidden}`}
      >
        {row.balances} {row.from}
      </td>
      {/*   <td className={styles.priceTableRow__action}>
        {priceRow.isAvailable ? (
          <LinkButton
            to={priceRow.url}
            className={styles.priceTableRow__button}
            wireframe
          >
            <Translation k={'Buy now'} />
          </LinkButton>
        ) : (
          <Translation k={'Coming soon'} />
        )}
      </td> */}
    </tr>
  );
};
