import { FunctionComponent } from 'react';
import { PageHeader } from '../../components/layout/pageHeader';
import { TextGrid } from '../../components/layout/textGrid';
import { MarkdownParser, TextBlock } from '../../components/layout/textBlock';
import { Container } from '../../components/layout/container';
import { Main } from '../../components/layout/main';
// import { useLanguage } from "../../store/slices/common/selectors";
// import { Loader } from "../../components/common/loader";
// import { useGetAboutUsQuery } from "../../store/api/aboutUs";

import data from '../../translations/dictionary.json';

export const AboutUs: FunctionComponent = () => {
  // const lang = useLanguage();
  // const { data } = useGetAboutUsQuery({ lang: lang || "en" });

  // if (!data) {
  //   return (
  //     <Main>
  //       <Loader />
  //     </Main>
  //   );
  // }
  return (
    <Main>
      <PageHeader subtitle={data['about-subtitle']} title={data['about-title']}>
        <MarkdownParser text={data['about-titleContent']} />
      </PageHeader>
      <Container>
        <TextGrid>
          <TextBlock text={data['about-content']} markdown />
        </TextGrid>
      </Container>
    </Main>
  );
};
