import { FunctionComponent } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container } from '../../../components/layout/container';
import styles from './questionsSection.module.css';
// import { useGetQuestionsQuery } from '../../../store/api/questions';
// import { useLanguage } from '../../../store/slices/common/selectors';
import { MarkdownParser } from '../../../components/layout/textBlock';

import data from '../../../translations/dictionary.json';

export const QuestionsSection: FunctionComponent = () => {
  const { category } = useParams();
  // const lang = useLanguage();
  // const { data } = useGetQuestionsQuery({ lang: lang || 'en' });

  console.log('data', data);

  if (!data) {
    return null;
  }

  const questions =
    data['questions-content'].find((c) => c.categorySlug === category)
      ?.questions || [];

  return (
    <Container>
      <div className={styles.questionsSection}>
        <nav className={styles.questionsSection__nav}>
          {data['questions-content'].map((c) => (
            <Link
              key={c.categorySlug}
              to={`/questions/${c.categorySlug}`}
              className={`${styles.questionsSection__navLink} ${
                category === c.categorySlug &&
                styles.questionsSection__navLink_active
              }`}
              replace
            >
              {c.category}
            </Link>
          ))}
        </nav>
        <div className={styles.questionsSection__detailsGrid}>
          {questions.map((question, index) => (
            <details
              className={styles.questionsSection__details}
              key={question.question + index + category}
            >
              <summary className={styles.questionsSection__detailsSummary}>
                <span className={styles.questionsSection__detailsIcon} />
                <span>{question.question}</span>
              </summary>
              <div
                className={`${styles.questionsSection__detailsContent} text`}
              >
                <MarkdownParser text={question.answer} />
              </div>
            </details>
          ))}
        </div>
      </div>
    </Container>
  );
};
