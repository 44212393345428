import { FunctionComponent } from "react";
import styles from "./errors.module.css";

export const Errors: FunctionComponent<{ errors?: string[] }> = ({
  errors,
}) => {
  if (!errors) {
    return null;
  }
  return (
    <div className={styles.errors}>
      {errors.map((error, index) => (
        <p className={styles.errors__error} key={index}>
          {error}
        </p>
      ))}
    </div>
  );
};
