import { FunctionComponent, TextareaHTMLAttributes } from "react";
import styles from "./textarea.module.css";
import { Errors } from "../errors";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  errors?: string[];
}

export const Textarea: FunctionComponent<TextareaProps> = ({ ...rest }) => {
  return (
    <div className={styles.textarea}>
      <textarea className={styles.textarea__field} rows={5} {...rest} />
      <Errors errors={rest.errors} />
    </div>
  );
};
