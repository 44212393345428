import { FunctionComponent, HTMLAttributes } from "react";
import styles from "./careerDetails.module.css";

export interface CareerDetailsProps extends HTMLAttributes<HTMLDetailsElement> {
  summary?: string;
}

export const CareerDetails: FunctionComponent<CareerDetailsProps> = ({
  summary,
  children,
}) => {
  return (
    <details className={styles.careerDetails}>
      <summary className={styles.careerDetails__summary}>
        <span>{summary}</span>
        <span className={styles.careerDetails__summaryAction} />
      </summary>
      <div className={styles.careerDetails__body}>{children}</div>
    </details>
  );
};
