export const useModifiers = (
  styles: { [x: string]: string } = {},
  base: string,
  mods: { [x: string]: boolean | null | undefined },
  options?: boolean | { withBaseSelector?: boolean; inherited?: string }
) => {
  return Object.keys(mods).reduce(
    (accumulator, mod) => {
      if (!mods[mod]) return accumulator;
      return `${accumulator} ${styles[`${base}_${mod}`]}`;
    },
    options
      ? `${
          typeof options === "boolean"
            ? styles[base]
            : `${options.inherited || ""} ${styles[base]}`
        } `
      : ""
  );
};
