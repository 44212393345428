import { useModifiers } from "hooks/useModifiers";
import React, {
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./langDropdown.module.css";

export interface LanguageDropdownProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "onSelect"> {
  options: { value: string; label: string | ReactNode }[];
  onSelect?: (value: string) => void;
  value: string;
}

export const LanguageDropdown: FunctionComponent<LanguageDropdownProps> = ({
  options,
  onSelect,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const mods = useModifiers(styles, "langDropdown", { open });

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };
    const clickOutsideHandler = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };
    document.addEventListener("keydown", handler);
    document.addEventListener("click", clickOutsideHandler);
    return () => {
      document.removeEventListener("keydown", handler);
      document.removeEventListener("click", clickOutsideHandler);
    };
  }, [open]);

  const handleSelect = (value: string) => {
    if (onSelect) {
      onSelect(value);
    }
    setOpen(false);
  };

  return (
    <div className={`${styles.langDropdown} ${mods}`} ref={ref}>
      <button
        className={styles.langDropdown__trigger}
        onClick={() => setOpen(!open)}
      >
        <span className={styles.langDropdown__triggerLabelWrapper}>
          {options.find((item) => value === item.value)?.label}
        </span>
        <svg width={11} height={7} xmlns="http://www.w3.org/2000/svg">
          <use href="#chevronDownTiny" />
        </svg>
      </button>
      <div className={styles.langDropdown__dropdown}>
        {options
          .map((item) => (
            <button
              className={styles.langDropdown__dropdownButton}
              key={item.value}
              onClick={() => handleSelect(item.value)}
            >
              {item.label}
            </button>
          ))}
      </div>
    </div>
  );
};
