import {
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
} from '@reduxjs/toolkit';
import { api } from './api';
import { calculatorSlice } from './slices/calculator';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { commonSlice } from './slices/common';
import { dictionarySlice } from './slices/dictionary';
import { reducer as exchangeReducer } from './slices/exchange';

export const rtkQueryErrorHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const status = action?.payload?.status;
      if (status) {
        api.dispatch(commonSlice.actions.setError(status));
      }
    }

    return next(action);
  };

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    calculator: calculatorSlice.reducer,
    common: commonSlice.reducer,
    dictionary: dictionarySlice.reducer,
    exchangeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, rtkQueryErrorHandler),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
