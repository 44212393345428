import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DictionaryType } from "../../api/dictionary/types";

const dictionaryFromStage = localStorage.getItem("dictionary");

const defaultDictionary = dictionaryFromStage
  ? JSON.parse(dictionaryFromStage)
  : null;

const initialState: {
  dictionary: Record<string, string> | null;
} = {
  dictionary: defaultDictionary,
};

export const dictionarySlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setDictionary: (state, action: PayloadAction<DictionaryType>) => {
      state.dictionary = action.payload.translates.content;
      localStorage.setItem(
        "dictionary",
        JSON.stringify(action.payload.translates.content)
      );
    },
  },
});
