import { FunctionComponent } from 'react';
import styles from './layout.module.css';
import { Outlet } from 'react-router-dom';
import { Nav } from '../nav';
import { Footer } from '../footer';
// import { Alert } from "../alert";
import { CookiePopup } from '../cookiePopup';

export const Layout: FunctionComponent = () => {
  return (
    <div className={styles.layout}>
      {/* <Alert /> */}
      <Nav />
      <Outlet />
      <Footer />
      <CookiePopup />
    </div>
  );
};
