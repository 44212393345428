import React, { FunctionComponent } from "react";

export const Icons: FunctionComponent = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol
          id="arrowRight"
          width="25"
          height="16"
          fill="none"
          viewBox="0 0 25 16"
        >
          <path
            d="M1 8L23 8"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="square"
          />
          <path
            d="M18 2L23.5 8L18 14"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="square"
          />
        </symbol>
        <symbol
          id="arrowRightSmall"
          width="19"
          height="9"
          viewBox="0 0 19 9"
          fill="none"
        >
          <path d="M0.644531 4.80396L17.8602 4.80396" stroke="black" />
          <path
            d="M14.4014 0.738281L18.2749 4.6118L14.4014 8.48532"
            stroke="black"
          />
        </symbol>
        <symbol
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="transaction"
        >
          <path
            d="M26.7388 10.9411H2.59192C1.75112 10.9411 1.33004 9.92451 1.92458 9.32997L9.27921 1.97534"
            stroke="currentColor"
            strokeWidth="2.3594"
            strokeLinecap="round"
          />
          <path
            d="M2.20165 17.5475L26.3485 17.5475C27.1893 17.5475 27.6104 18.564 27.0159 19.1586L19.6612 26.5132"
            stroke="currentColor"
            strokeWidth="2.3594"
            strokeLinecap="round"
          />
        </symbol>
        <symbol
          width="17"
          height="10"
          viewBox="0 0 17 10"
          fill="none"
          id={"chevronDownSmall"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 0.73999L8.55009 8.29008L16.1002 0.73999"
            stroke="currentColor"
            strokeWidth="1.88752"
          />
        </symbol>
        <svg
          width="11"
          height="7"
          viewBox="0 0 11 7"
          fill="none"
          id={"chevronDownTiny"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.3994 1.31655L10.0628 0.972265C9.95681 0.864746 9.81585 0.805513 9.66519 0.805513C9.51462 0.805513 9.37349 0.864746 9.26754 0.972265L5.42721 4.87267L1.58245 0.967937C1.47667 0.860418 1.33554 0.80127 1.18497 0.80127C1.0344 0.80127 0.893182 0.860418 0.787314 0.967937L0.450575 1.3101C0.231319 1.53261 0.231319 1.89505 0.450575 2.11756L5.02822 6.78332C5.134 6.89075 5.27497 6.96645 5.42688 6.96645H5.42863C5.57929 6.96645 5.72025 6.89067 5.82603 6.78332L10.3994 2.1302C10.5054 2.02276 10.5635 1.87528 10.5637 1.72236C10.5637 1.56935 10.5054 1.4239 10.3994 1.31655Z"
            fill="currentColor"
          />
        </svg>

        <symbol
          width="26"
          height="12"
          viewBox="0 0 26 12"
          fill="none"
          id={"mobileMenu"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1H25"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
          <path
            d="M1 10.6001H25"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </symbol>
        <symbol
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          id={"mobileMenuClose"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.7846 20.7843L3.21533 3.21509"
            stroke="currentColor"
            strokeWidth="2"
          />
          <path
            d="M3.21447 20.7846L20.7837 3.21533"
            stroke="currentColor"
            strokeWidth="2"
          />
        </symbol>
        <symbol
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          id={"phone"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.62 7.79C5.06 10.62 7.38 12.93 10.21 14.38L12.41 12.18C12.68 11.91 13.08 11.82 13.43 11.94C14.55 12.31 15.76 12.51 17 12.51C17.55 12.51 18 12.96 18 13.51V17C18 17.55 17.55 18 17 18C7.61 18 0 10.39 0 1C0 0.45 0.45 0 1 0H4.5C5.05 0 5.5 0.45 5.5 1C5.5 2.25 5.7 3.45 6.07 4.57C6.18 4.92 6.1 5.31 5.82 5.59L3.62 7.79Z"
            fill="currentColor"
          />
        </symbol>
        <symbol
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          id={"mail"}
        >
          <path
            d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0ZM18 4L10 9L2 4V2L10 7L18 2V4Z"
            fill="currentColor"
          />
        </symbol>
        <symbol
          width="61"
          height="61"
          viewBox="0 0 61 61"
          fill="none"
          id={"submit"}
        >
          <path
            d="M13.026 47.4066L47.0752 13.3574"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
          />
          <path
            d="M22.2715 12.7285H47.7278V38.1849"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
          />
        </symbol>
        <symbol
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          id={"skrill"}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.2365 9.62588C11.2365 8.19528 10.0839 7.26364 8.15198 7.13454L8.15082 7.1357L8.14372 7.13518C7.86921 7.11494 7.06914 7.05598 7.06914 6.32153C7.06914 5.44921 8.1578 5.44921 8.51603 5.44921C9.25925 5.44921 10.1723 5.69812 10.5305 5.80512C10.5335 5.80627 10.5426 5.80957 10.5568 5.81471C10.6144 5.83554 10.7555 5.88662 10.9132 5.94818V4.41871C10.2781 4.20122 9.53606 4 8.17757 4C6.71207 4 5 4.65598 5 6.50298C5 8.49187 7.08194 8.84894 7.97636 8.90942C8.6684 8.95245 9.01732 9.22927 9.01732 9.73289C9.01732 10.3342 8.4288 10.6924 7.4425 10.6924C6.66305 10.6861 5.89003 10.5509 5.15469 10.2923V11.873C6.0568 12.0814 6.98078 12.1802 7.90657 12.1672C10.2072 12.1672 11.2365 10.8913 11.2365 9.62588Z"
            fill="currentColor"
          />
        </symbol>
        <symbol
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          id={"card"}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.3037 2C0.583688 2 0 2.58369 0 3.3037V12.4296C0 13.1496 0.583688 13.7333 1.3037 13.7333H14.6963C15.4163 13.7333 16 13.1496 16 12.4296V3.3037C16 2.58369 15.4163 2 14.6963 2H1.3037ZM2.13333 6H5.33333V4.4L2.13333 4.4L2.13333 6ZM4.26667 12.1333H2.13333V11.0667H4.26667V12.1333ZM5.33333 12.1333H7.46667L7.46667 11.0667H5.33333V12.1333ZM10.6667 12.1333H8.53333V11.0667H10.6667V12.1333ZM11.7333 12.1333H13.8667V11.0667H11.7333V12.1333Z"
            fill="currentColor"
          />
        </symbol>
        <symbol
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          id={"neteller"}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 1H1V15H15V1ZM9.88233 4.5H12.1603L10.8356 11.5H8.43075L6.91435 7.09259L6.13522 11.5H3.8397L5.13194 4.5H7.53279L9.09645 8.78993L9.88233 4.5Z"
            fill="currentColor"
          />
        </symbol>
        <symbol
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          id={"sepa"}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.84871 11.9862C9.77107 11.9097 10.6408 11.518 11.3161 10.8749L11.3335 10.8575L11.3233 10.8348L10.864 9.8335L10.8408 9.78363L10.8066 9.82642C10.5266 10.1778 10.1675 10.4554 9.75965 10.6359C9.35181 10.8164 8.9072 10.8945 8.46342 10.8634C8.01965 10.8324 7.58974 10.6932 7.21006 10.4577C6.83038 10.2221 6.51208 9.89708 6.28207 9.51007H10.6803L10.6573 9.46079L10.1286 8.30785L10.1196 8.28778H5.88635C5.8791 8.20279 5.87503 8.11721 5.87503 8.03282C5.87525 7.90221 5.88418 7.77178 5.90174 7.6424H9.82205L9.7994 7.59342L9.27042 6.44017L9.26142 6.4204H6.36685C6.6055 6.06605 6.92074 5.77188 7.28845 5.56042C7.65616 5.34895 8.06659 5.22579 8.48834 5.20036C8.91008 5.17493 9.33196 5.2479 9.72169 5.41369C10.1114 5.57948 10.4587 5.83369 10.7369 6.15688L10.797 6.22534V4.70264L10.7822 4.69261C10.1114 4.23899 9.32326 3.99794 8.5176 4.00001C6.90425 4.00001 5.45464 4.94846 4.81766 6.41981H3.38343L3.40579 6.46909L3.93419 7.62204L3.94319 7.64211H4.50729C4.49684 7.7631 4.49104 7.88113 4.49104 7.99917C4.49104 8.08829 4.4951 8.18302 4.50265 8.28749H3.3335L3.35614 8.33677L3.88512 9.48942L3.89412 9.50948H4.78949C5.08902 10.2364 5.59235 10.8577 6.23678 11.296C7.0057 11.8194 7.92636 12.0627 8.84871 11.9862Z"
            fill="currentColor"
          />
        </symbol>
      </svg>
    </>
  );
};
