/* eslint-disable @typescript-eslint/no-explicit-any */
// http.ts
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';

import { getItem } from './persistanceStorage';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'Access-Allow-Origin': '*',
  // 'Access-Control-Allow-Origin': '*', //'http://localhost:9000',
  // 'Access-Control-Allow-Credentials': true,
  // 'Access-Control-Allow-Headers': '*',
  // 'Access-Control-Allow-Methods': 'OPTIONS,POST,GET',
  // 'X-Requested-With': 'XMLHttpRequest',
  // Origin: 'http://localhost:9000',
  // referrerPolicy: 'strict-origin-when-cross-origin',
  // Referer: 'https://app.sensilize.com',
  // mode: 'no-cors', // same-origin, no-cors, cors
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  try {
    const token = getItem('token', false);
    const lang = getItem('lang', false);

    if (config && token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (config && lang) {
      config.headers['Accept-Language'] = lang;
    }

    return config;
  } catch (error) {
    console.log('config error', error);
    throw new Error();
  }
};

// const isProduction = process.env.NODE_ENV === 'production'

class Http {
  private instance: AxiosInstance | null = null;

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      baseURL: process.env.REACT_APP_API_EXCHANGE,

      headers,
      // withCredentials: true,
    });

    http.interceptors.request.use(
      (config) => injectToken(config),
      (error) => Promise.reject(error)
    );

    http.interceptors.response.use(
      (response) => response,
      (error) => {
        const { response } = error;
        return this.handleError(response);
      }
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(
    config: InternalAxiosRequestConfig
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: InternalAxiosRequestConfig
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: InternalAxiosRequestConfig
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: InternalAxiosRequestConfig
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: InternalAxiosRequestConfig
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  private handleError(error: AxiosError) {
    // console.log('handleError', error)

    const { status } = error;

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCode.Unauthorized: {
        console.log(' Handle Unauthorized');

        // Handle Unauthorized
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
    }

    return Promise.reject(error);
  }
}

export const http = new Http();
