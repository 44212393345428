import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../index';

const courses = (state: RootState) => state.exchangeReducer;
export const coursesSelector = createSelector(
  courses,
  (exchangeReducer) => exchangeReducer.courses
);

const cryptocurrencies = (state: RootState) => state.exchangeReducer;
export const cryptocurrenciesSelector = createSelector(
  cryptocurrencies,
  (exchangeReducer) => exchangeReducer.cryptocurrencies
);

const fiats = (state: RootState) => state.exchangeReducer;
export const fiatsSelector = createSelector(
  fiats,
  (exchangeReducer) => exchangeReducer.fiats
);

const balances = (state: RootState) => state.exchangeReducer;
export const balancesSelector = createSelector(
  balances,
  (exchangeReducer) => exchangeReducer.balances
);

const limits = (state: RootState) => state.exchangeReducer;
export const limitsSelector = createSelector(
  limits,
  (exchangeReducer) => exchangeReducer.limits
);

const payMethods = (state: RootState) => state.exchangeReducer;
export const payMethodsSelector = createSelector(
  payMethods,
  (exchangeReducer) => exchangeReducer.payMethods
);

const payments = (state: RootState) => state.exchangeReducer;
export const paymentsSelector = createSelector(
  payments,
  (exchangeReducer) => exchangeReducer.payments
);

const types = (state: RootState) => state.exchangeReducer;
export const typesSelector = createSelector(
  types,
  (exchangeReducer) => exchangeReducer.types
);
