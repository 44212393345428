import React, { FunctionComponent } from "react";
import { PageHeader } from "../../components/layout/pageHeader";
import { TextGrid } from "../../components/layout/textGrid";
import { MarkdownParser, TextBlock } from "../../components/layout/textBlock";
import { Container } from "../../components/layout/container";
import { Main } from "../../components/layout/main";
import { useLanguage } from "../../store/slices/common/selectors";
import { useGetAffiliateQuery } from "../../store/api/affiliate";
import { Loader } from "../../components/common/loader";
import image from "../../images/headers/coin.svg";
import styles from "./affiliate.module.css";
import { BigButtonLink } from "../../components/common/bigButton";

export const Affiliate: FunctionComponent = () => {
  const lang = useLanguage();
  const { data } = useGetAffiliateQuery({ lang: lang || "en" });

  if (!data) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }

  return (
    <Main>
      <PageHeader subtitle={data.subtitle} title={data.title} image={image}>
        <MarkdownParser text={data.titleContent} />
      </PageHeader>
      <Container>
        <TextGrid>
          {data.contentTitle ? (
            <h2 className={`h3 ${styles.affiliate__title}`}>
              {data.contentTitle}
            </h2>
          ) : null}
          <TextBlock text={data.content} markdown />
          {data.link ? (
            <div className={styles.affiliate__action}>
              <BigButtonLink href={data.link.url} autoWidth>
                  <span>{data.link.text}</span>
                <svg width={25} height={16} xmlns="http://www.w3.org/2000/svg">
                  <use href="#arrowRight" />
                </svg>
              </BigButtonLink>
            </div>
          ) : null}
        </TextGrid>
      </Container>
    </Main>
  );
};
