import { FunctionComponent, HTMLAttributes, ReactNode } from "react";
import styles from "./textGrid.module.css";

export interface TextGridProps extends HTMLAttributes<HTMLDivElement> {
  aside?: ReactNode;
}

export const TextGrid: FunctionComponent<TextGridProps> = ({
  children,
  aside,
  ...rest
}) => {
  return (
    <div className={styles.textGrid} {...rest}>
      <div className={styles.textGrid__body}>{children}</div>
      {aside ? <div className={styles.textGrid__aside}>{aside}</div> : null}
    </div>
  );
};
