import { AnchorHTMLAttributes, FunctionComponent } from "react";
import { Link, LinkProps } from "react-router-dom";
import styles from "./bigButton.module.css";
import { useModifiers } from "../../../hooks/useModifiers";

export interface BigButtonProps extends LinkProps {}

export const BigButton: FunctionComponent<BigButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <Link className={`${styles.bigButton} ${className}`} {...rest}>
      {children}
    </Link>
  );
};

export interface BigButtonLinkProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  autoWidth?: boolean;
}

export const BigButtonLink: FunctionComponent<BigButtonLinkProps> = ({
  children,
  className,
  autoWidth,
  ...rest
}) => {
  const mods = useModifiers(styles, "bigButton", { auto: autoWidth });

  return (
    <a className={`${styles.bigButton} ${className} ${mods}`} {...rest}>
      {children}
    </a>
  );
};
