import { FunctionComponent, HTMLAttributes } from "react";
import styles from "./container.module.css";

export const Container: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({
  className,
  children,
  ...rest
}) => {
  return (
    <div className={`${styles.container} ${className || ""}`} {...rest}>
      {children}
    </div>
  );
};
