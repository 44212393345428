import { Main } from '../../components/layout/main';
import { PageHeader } from '../../components/layout/pageHeader';
import { FunctionComponent } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
// import { useLanguage } from '../../store/slices/common/selectors';
// import { useGetQuestionsQuery } from '../../store/api/questions';
import { Loader } from '../../components/common/loader';
import { MarkdownParser } from '../../components/layout/textBlock';

import data from '../../translations/dictionary.json';

export const Questions: FunctionComponent = () => {
  const { category } = useParams();
  // const lang = useLanguage();
  // const f = useGetQuestionsQuery({ lang: lang || 'en' });

  if (!data) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }

  if (
    !category ||
    !data['questions-content'].find((c) => c.categorySlug === category)
  ) {
    return (
      <Navigate
        to={`/questions/${data['questions-content'][0].categorySlug}`}
        replace
      />
    );
  }
  return (
    <Main>
      <PageHeader
        subtitle={data['questions-subtitle']}
        title={data['questions-title']}
      >
        <MarkdownParser text={data['questions-titleContent']} />
      </PageHeader>
      <Outlet />
    </Main>
  );
};
