import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './style/theme.css';
import './style/typography.css';
import './style/common.css';
import './style/icon.css';
import './index.css';
import 'focus-visible';
import { Icons } from './icons';
import { store } from './store';
import { Provider } from 'react-redux';
import ScrollToTop from './components/layout/scrollToTop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <Icons />
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
