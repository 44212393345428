import { FunctionComponent } from "react";
import { PageHeader } from "../../components/layout/pageHeader";
import { Container } from "../../components/layout/container";
import { TextGrid } from "../../components/layout/textGrid";
import { CareerDetails } from "./careerDetails";
import { MarkdownParser, TextBlock } from "../../components/layout/textBlock";
import { Main } from "../../components/layout/main";
import { useLanguage } from "../../store/slices/common/selectors";
import { Loader } from "../../components/common/loader";
import { useGetCareersQuery } from "../../store/api/careers";

export const Careers: FunctionComponent = () => {
  const lang = useLanguage();
  const { data } = useGetCareersQuery({ lang: lang || "en" });

  if (!data) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }
  return (
    <Main>
      <PageHeader subtitle={data.subtitle} title={data.title}>
        <MarkdownParser text={data.titleContent} />
      </PageHeader>
      <Container>
        <TextGrid>
          {data.sections.map((career) => (
            <CareerDetails summary={career.title}>
              <TextBlock text={career.content} markdown/>
            </CareerDetails>
          ))}
        </TextGrid>
      </Container>
    </Main>
  );
};
