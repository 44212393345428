import { Children, FunctionComponent, HTMLAttributes, ReactNode } from "react";
import styles from "./footerMenu.module.css";

export interface FooterMenuProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {
  title: string | ReactNode;
}

export const FooterMenu: FunctionComponent<FooterMenuProps> = ({
  title,
  children,
}) => {
  return (
    <div className={styles.footerMenu}>
      <h2 className={`${styles.footerMenu__title} h4 h4_noMargin`}>{title}</h2>
      <ul className={styles.footerMenu__list}>
        {Children.map(children, (child) => (
          <li className={styles.footerMenu__item}>{child}</li>
        ))}
      </ul>
    </div>
  );
};
