import { api } from "../index";
import { CareersResponseType } from "./types";

const careersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCareers: build.query<CareersResponseType, { lang: string }>({
      query: ({ lang }) => `page/${lang}/vacancy`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCareersQuery } = careersApi;
