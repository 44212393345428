import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import {
  AnchorHTMLAttributes,
  FunctionComponent,
  HTMLAttributes,
  ReactNode,
} from "react";
import styles from "./button.module.css";
import { useModifiers } from "../../../hooks/useModifiers";

export interface LinkProps extends RouterLinkProps {
  ghost?: boolean;
  outline?: boolean;
  wireframe?: boolean;
}

export const LinkButton: FunctionComponent<LinkProps> = ({
  children,
  className,
  ghost,
  outline,
  wireframe,
  ...rest
}) => {
  const mods = useModifiers(
    styles,
    "button",
    { ghost, outline, wireframe },
    { withBaseSelector: true, inherited: className }
  );

  return (
    <RouterLink className={mods} {...rest}>
      <span className={styles.button__label}>{children}</span>
    </RouterLink>
  );
};

export interface AnchorProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  ghost?: boolean;
  outline?: boolean;
  wireframe?: boolean;
  outlineColor?: boolean;
  icon?: ReactNode;
}

export const AnchorButton: FunctionComponent<AnchorProps> = ({
  children,
  className,
  ghost,
  outline,
  wireframe,
  outlineColor,
  icon,
  ...rest
}) => {
  const mods = useModifiers(
    styles,
    "button",
    { ghost, outline, wireframe, outlineColor },
    { withBaseSelector: true, inherited: className }
  );

  return (
    <a className={mods} {...rest}>
      <span className={styles.button__label}>{children}</span>
      {icon ? <span className={styles.button__icon}>{icon}</span> : null}
    </a>
  );
};

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  ghost?: boolean;
  outline?: boolean;
  wireframe?: boolean;
  iconOnly?: boolean;
}

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  className,
  ghost,
  outline,
  wireframe,
  iconOnly,
  ...rest
}) => {
  const mods = useModifiers(
    styles,
    "button",
    { ghost, outline, wireframe, iconOnly },
    { withBaseSelector: true, inherited: className }
  );

  return (
    <button className={mods} {...rest}>
      <span className={styles.button__label}>{children}</span>
    </button>
  );
};
