import { FunctionComponent, SelectHTMLAttributes } from "react";
import styles from "./select.module.css";
import {Errors} from "../errors";

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: Array<{ value: string; label: string }>;
  value?: string;
  errors?: string[];
}

export const Select: FunctionComponent<SelectProps> = ({
  options,
  value,
  errors,
  ...rest
}) => {
  return (
    <div className={styles.select}>
      <select className={styles.select__filed} value={value} {...rest}>
        {options.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
      <Errors errors={errors} />
    </div>
  );
};
