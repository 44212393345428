import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ICourseObg,
  ICourses,
  ILimit,
  IPayment,
  IRequestCalculation,
  // IRequestExchange,
  ITransformCourse,
} from './types';
import ExchangeService from './ExchangeService';

interface ExchangeState {
  courses: ITransformCourse[];
  cryptocurrencies: ICourseObg[];
  fiats: ICourseObg[];
  balances: ICourses | null;
  limits: ILimit[];
  payMethods: ICourseObg[];
  payments: IPayment[];
  types: ICourseObg[];
}

const initialState: ExchangeState = {
  courses: [],
  cryptocurrencies: [],
  fiats: [],
  balances: null,
  limits: [],
  payMethods: [],
  payments: [],
  types: [],
};

export const calculationThunk = createAsyncThunk(
  'exchange/calculationThunk',
  async (data: IRequestCalculation, { rejectWithValue }) => {
    try {
      return await ExchangeService.calculation(data);
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

// export const exchangeThunk = createAsyncThunk(
//   'exchange/exchangeThunk',
//   async (data: IRequestExchange, { rejectWithValue }) => {
//     try {
//       return await ExchangeService.exchange(data);
//     } catch (errors) {
//       return rejectWithValue(errors);
//     }
//   }
// );

// export const createExchangeThunk = createAsyncThunk(
//   'exchange/createExchangeThunk',
//   async (data: any, { rejectWithValue }) => {
//     try {
//       return await ExchangeService.createExchange(data);
//     } catch (errors) {
//       return rejectWithValue(errors);
//     }
//   }
// );

export const getCourseThunk = createAsyncThunk(
  'exchange/getCourseThunk',
  async (_, { rejectWithValue }) => {
    try {
      return await ExchangeService.getCourse();
    } catch (errors) {
      return rejectWithValue(errors);
    }
  }
);

const exchangeSlice = createSlice({
  name: 'exchange',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCourseThunk.fulfilled, (state, action) => {
        state.courses = action.payload.courses;
        state.cryptocurrencies = action.payload.cryptocurrencies;
        state.fiats = action.payload.fiats;
        state.balances = action.payload.balances;
        state.limits = action.payload.limits;
        state.payMethods = action.payload.payMethods;
        state.payments = action.payload.payments;
        state.types = action.payload.types;
      })
      .addCase(getCourseThunk.rejected, (state) => {
        state.courses = [];
        state.cryptocurrencies = [];
        state.fiats = [];
        state.balances = null;
        state.limits = [];
        state.payMethods = [];
        state.payments = [];
        state.types = [];
      });
  },
});

export const { actions, reducer } = exchangeSlice;
