import { Translation } from "../../translations/translation";
import React, { FunctionComponent } from "react";
import { Container } from "../../components/layout/container";
import { Link } from "react-router-dom";
import logo from "../../images/logo.svg";
import styles from "./errorPage.module.css";
import { AnchorButton } from "../../components/common/button";
import { PageHeader } from "../../components/layout/pageHeader";
import { Main } from "../../components/layout/main";

export const ErrorPage: FunctionComponent<{ code?: number | string }> = ({
  code,
}) => {
  return (
    <div className={styles.errorPage}>
      <Main>
        <Container>
          <Link to={"/"} className={styles.errorPage__logo}>
            <img src={logo} alt={''} />
          </Link>
        </Container>

        <PageHeader
          subtitle={
            `${code}` !== "404" ? (
              <Translation k={"Something went wrong"} />
            ) : (
              null || <Translation k={"Not found"} />
            )
          }
          title={`${code}` || "404"}
        ></PageHeader>
        <Container>
          <AnchorButton href={"/"} className={styles.errorPage__button}>
            <Translation k={"Return to home page"} />
          </AnchorButton>
        </Container>
      </Main>
    </div>
  );
};
