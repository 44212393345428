import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const defaultLanguage = localStorage.getItem("language") || null;
document.documentElement.setAttribute("lang", defaultLanguage || "en");

const initialState: {
  language: string | null;
  error: string | number | null;
} = {
  language: defaultLanguage,
  error: null,
};

export const commonSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
      document.documentElement.setAttribute("lang", action.payload);
      localStorage.setItem("language", action.payload);
    },
    setError: (state, action: PayloadAction<string | number | null>) => {
      state.error = action.payload;
    },
  },
});
