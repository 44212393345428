import { useModifiers } from "hooks/useModifiers";
import { FunctionComponent, HTMLAttributes } from "react";
import styles from "./cardGrid.module.css";

interface CardGridProps extends HTMLAttributes<HTMLDivElement> {
  narrow?: boolean;
}

export const CardGrid: FunctionComponent<CardGridProps> = ({
  children,
  narrow,
}) => {
  const mods = useModifiers(styles, "cardGrid", { narrow });

  return <div className={`${styles.cardGrid} ${mods}`}>{children}</div>;
};

interface CardGridItemProps extends HTMLAttributes<HTMLDivElement> {}

export const CardGridItem: FunctionComponent<CardGridItemProps> = ({
  children,
}) => {
  return <div className={`${styles.cardGrid__item}`}>{children}</div>;
};
