import { api } from "../index";
import { AffiliateResponseType } from "./types";

const aboutUsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAffiliate: build.query<AffiliateResponseType, { lang: string }>({
      query: ({ lang }) => `page/${lang}/affiliate`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAffiliateQuery } = aboutUsApi;
