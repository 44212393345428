import { FunctionComponent, ReactNode } from "react";
import { PageHeader } from "../../components/layout/pageHeader";
import { Main } from "../../components/layout/main";
import { Translation } from "../../translations/translation";

export const Error: FunctionComponent<{
  code?: number | string;
  error?: string | ReactNode | null;
}> = ({ code, error }) => {
  return (
    <Main>
      <PageHeader
        subtitle={error || <Translation k={"Not found"} />}
        title={code ? `${code}` : "404"}
      ></PageHeader>
    </Main>
  );
};
