import styles from "./loader.module.css";
import { FunctionComponent } from "react";
import { useModifiers } from "../../../hooks/useModifiers";

export const Loader: FunctionComponent<{ narrow?: boolean }> = ({ narrow }) => {
  const mods = useModifiers(styles, "loader", { narrow });

  return (
    <span className={`${mods} ${styles.loader__container}`}>
      <span className={styles.loader}>
        <span />
        <span />
        <span />
        <span />
      </span>
    </span>
  );
};
