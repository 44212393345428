import { FunctionComponent, InputHTMLAttributes } from "react";
import styles from "./input.module.css";
import {Errors} from "../errors";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  errors?: string[];
}

export const Input: FunctionComponent<InputProps> = ({ errors, ...rest }) => {
  return (
    <div className={styles.input}>
      <input className={styles.input__field} {...rest} />
      <Errors errors={errors} />
    </div>
  );
};
